<template>
  <div class="btc-info-picker-box">
    <div class="item hand" @click="handleClickYear">
      <div class="label">
        <span>{{ selectYear || '年' }}</span>
        <div :class="{ 'arrow-up': !showYear, 'arrow-down': showYear }" class="btc-arrow ac-1" style="margin-left: 10px;"/>
      </div>
      <div v-show="showYear" class="pull-down">
        <div v-for="(item, index) in year" :key="item" :class="{ active: yearIndex === index }" @click.stop="handleClickYearItem(item, index)">{{ item }}</div>
      </div>
    </div>
    <div class="item hand" @click="handleClickMonth">
      <div class="label">
        <span>{{ selectMonth || '月' }}</span>
        <div :class="{ 'arrow-up': !showMonth, 'arrow-down': showMonth }" class="btc-arrow ac-1" style="margin-left: 10px;"/>
      </div>
      <div v-show="showMonth" class="pull-down">
        <div v-for="(item, index) in month" :key="item.value" :class="{ active: monthIndex === index }" @click.stop="handleClickMonthItem(item, index)">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTCInfoReportDatePicker',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showYear: false,
      showMonth: false,
      yearIndex: -1,
      monthIndex: -1,
      month: [],
      selectYear: '2024',
      selectMonth: '',
      monthArray: [
        { label: '一月', value: '1' },
        { label: '二月', value: '2' },
        { label: '三月', value: '3' },
        { label: '四月', value: '4' },
        { label: '五月', value: '5' },
        { label: '六月', value: '6' },
        { label: '七月', value: '7' },
        { label: '八月', value: '8' },
        { label: '九月', value: '9' },
        { label: '十月', value: '10' },
        { label: '十一月', value: '11' },
        { label: '十二月', value: '12' }
      ]
    }
  },
  computed: {
    year () {
      return this.data.years
    }
  },
  mounted () {
  },
  methods: {
    getMonth (year) {
      const monthArr = this.data[year]
      this.month = this.monthArray.filter(v => {
        return monthArr.includes(v.value)
      })
      if (this.month.length === 0) {
        this.month = [
          { label: '一月', value: '1' },
          { label: '二月', value: '2' },
          { label: '三月', value: '3' },
          { label: '四月', value: '4' },
          { label: '五月', value: '5' },
          { label: '六月', value: '6' },
          { label: '七月', value: '7' },
          { label: '八月', value: '8' },
          { label: '九月', value: '9' },
          { label: '十月', value: '10' },
          { label: '十一月', value: '11' },
          { label: '十二月', value: '12' }
        ]
      }
    },
    handleClickYear () {
      this.showYear = !this.showYear
    },
    handleClickMonth () {
      this.showMonth = !this.showMonth
    },
    handleClickYearItem (item, index) {
      this.getMonth(item)
      this.yearIndex = index
      this.selectYear = item
      this.$emit('change-year', item)
      this.showYear = false
    },
    handleClickMonthItem (item, index) {
      this.monthIndex = index
      this.selectMonth = item.label
      this.$emit('change-month', item.value)
      this.showMonth = false
    }
  }
}
</script>

<style lang="scss" scoped>

.btc-info-picker-box {
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d1d1d1;
  padding: 3px;
  background-color: #ffffff;
  font-size: 12px;
}

.btc-info-picker-box > .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  position: relative;
  padding: 0 10px;
}

.btc-info-picker-box > .item > .label {
  display: flex;
  align-items: center;
}

.btc-info-picker-box > .item:first-child {
  border-radius: 15px 0 0 15px;
}

.btc-info-picker-box > .item:last-child {
  margin-left: 5px;
  border-radius: 0 15px 15px 0;
}

.btc-info-picker-box > .item > .pull-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 23px;
  background-color: burlywood;
  z-index: 1;
}

.btc-info-picker-box > .item > .pull-down > div {
  width: 72px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.active {
  background-color: #cce0f1!important;
}

</style>
